<template>
    <!-- <Header title="Video" /> -->
    <CommonLayout>
    <section class="inner_banner_section about_us_banner top_margin">
        <div class="inner_banner_row"> <!-- main_container -->
            <div class="inner_banner_col">
                <div class="inner_banner_block">
                    <p class="body_heading tc">Videos</p>
                </div>
            </div>
        </div>
    </section>
    <!-- ---------- GALLERY SECTION ---------- -->
    <section class="gallery_section row_padding">
        <div class="gallery_row main_container">
            <div class="gallery_title_col">
                <div class="main_title_block">
                    <div class="body_heading_block desk_heading">
                        <p class="body_heading tc">Rio VIDEO</p>
                        <p class="body_font tc">Take a peek at what awaits you at Rio Colina Exotic Riverside Villas</p>
                    </div>
                </div>
            </div>
            <!-- Video -->
            <div class="gallery_col cottages_col">
                <div class="gallery_sub_heading">
                    <p class="data_sub_heading">Video</p>
                </div>
                <div class="gallery_block gallery_video_block">
                    <div class="gallery_container">
                        <div class="gallery">
                            <!-- <div class="gallery_video" v-for="res in list" :key="res.id">
                                <iframe :src="res.url" width="1280" height="720" :title="res.title" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div> -->
                            <div class="gallery_video" v-for="video in paginatedVideos" :key="video.id">
                                <iframe :src="video.url" width="560" height="315" :title="video.title" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                            <div class="paginateBtn bird bird--one">
                                <button @click="prevPage" :disabled="currentPage === 1" class="site_button">Previous</button>
                                <span>{{ currentPage }}</span>
                                <button @click="nextPage" :disabled="endIndex >= videos.length" class="site_button">Next</button>
                            </div>
                            <!-- <div class="gallery_video">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/cc7CjSRCcJU"
                                    title="Welcome to Rio Colina Club" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div>
                            <div class="gallery_video">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/Vt1GtRaymCA"
                                    title="Rio Colina 31st Party 2022" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div>
                            <div class="gallery_video">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/eTLdi89-TKA"
                                    title="HöLi Shört Film.. 2Ø22   🏘️🏝️🏘️" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div>
                            <div class="gallery_video">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/MrcpzMft6BQ"
                                    title="Rio Monsoon 2021 🌈" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div>
                            <div class="gallery_video">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/NygS9MmVDyc"
                                    title="Rio Colina Dhuleti  2020" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div>
                            <div class="gallery_video">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/m7Z4GTnuYqQ"
                                    title="Rio Colina 31st Party 2019" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div>
                            <div class="gallery_video">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/e1quGqibd3E"
                                    title="Rio Colina Holi 2019" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div>
                            <div class="gallery_video">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/kxm7NSertGE"
                                    title="KKB Rio Colina" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div>
                            <div class="gallery_video">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/xrM8K0iOlmM"
                                    title="Rio Colina Holi Event 2018." frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div> -->
                            <!-- <div class="gallery_video">
                                <iframe width="560" height="315"
                                    src="https://www.youtube.com/embed/eTLdi89-TKA?si=XfnRS81l8U65qqL6"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div> -->
                            <!-- <div class="gallery_video">
                                <iframe width="560" height="315"
                                    src="https://www.youtube.com/embed/e1quGqibd3E?si=8y6M41EtJ2_eKoLr"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>
                            </div> -->
                            <!-- <div class="gallery_video">
                                <iframe width="1280" height="720" src="https://www.youtube.com/embed/NygS9MmVDyc" title="Rio Colina Dhuleti  2020" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div class="gallery_video">
                                <iframe width="1280" height="720" src="https://www.youtube.com/embed/m7Z4GTnuYqQ" title="Rio Colina 31st Party 2019" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div> -->
                        </div>
                    </div>
                </div>
                <!-- Pagination Start -->
                <!-- <div class="row d-flex">
                    <div class="col-12 d-flex">
                        <div class="col-6 showing_entry">
                            Showing {{ list.from }} to
                            {{ list.to }} of
                            {{ list.total }} entries
                        </div>
                        <div class="col-6" v-if="list.total > 0">
                            <div class="pagination_part_one" style="display: flex;float: right;">
                                <ul class="pagination">
                                    <li class="page-item" v-for="(item, index) in list.last_page" :key="index">
                                        <a class="page-link" :class="list.current_page == item ? `active` : ``"
                                            @click="getResults(item)">
                                            {{ item }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- Pagination End -->
            </div>
        </div>
    </section>
    <!-- <Footer /> -->
    </CommonLayout>
</template>
<script>
// import Footer from '../../components/Footer.vue';
// import Header from '../../components/Header.vue';
import CommonLayout from '../../pages/User/CommonLayout.vue';
// import axios from "axios";

export default {
    name: 'Video',
    components: { 
        // Header, 
        // Footer,
        CommonLayout
    },
    data() {
        return {
            // list: [],
            videos: [
                { id: 1, title: 'Welcome to Rio Colina Club', url: 'https://www.youtube.com/embed/cc7CjSRCcJU' },
                { id: 2, title: 'Rio Colina 31st Party 2022', url: 'https://www.youtube.com/embed/Vt1GtRaymCA' },
                { id: 3, title: 'HöLi Shört Film.. 2Ø22   🏘️🏝️🏘️', url: 'https://www.youtube.com/embed/eTLdi89-TKA' },
                { id: 4, title: 'Rio Monsoon 2021 🌈', url: 'https://www.youtube.com/embed/MrcpzMft6BQ' },
                { id: 5, title: 'Rio Colina Dhuleti 2020', url: 'https://www.youtube.com/embed/NygS9MmVDyc' },
                { id: 6, title: 'Rio Colina 31st Party 2019', url: 'https://www.youtube.com/embed/m7Z4GTnuYqQ' },
                { id: 7, title: 'Rio Colina Holi 2019', url: 'https://www.youtube.com/embed/e1quGqibd3E' },
                { id: 8, title: 'KKB Rio Colina', url: 'https://www.youtube.com/embed/kxm7NSertGE' },
                { id: 9, title: 'Rio Colina Holi Event 2018.', url: 'https://www.youtube.com/embed/xrM8K0iOlmM' },
            ],
            currentPage: 1,
            videosPerPage: 3,
        }
    },
    computed: {
        startIndex() {
            return (this.currentPage - 1) * this.videosPerPage;
        },
        endIndex() {
            return this.currentPage * this.videosPerPage;
        },
        paginatedVideos() {
            return this.videos.slice(this.startIndex, this.endIndex);
        },
    },
    methods: {
        // async getResults() {
        //     await axios
        //         .get(process.env.VUE_APP_API_URL + "/gallery_video",
        //             {
        //                 headers: {
        //                     "Content-Type": "application/json",
        //                     'Accept': "application/json",
        //                     "Authorization": `Bearer ${this.token}`
        //                 }
        //             })
        //         .then((response) => {
        //             this.list = response.data.data;
        //             console.log(this.list);
        //         });
        // },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.endIndex < this.videos.length) {
                this.currentPage++;
            }
        },
    },
    mounted() {
        let recaptchaScript2 = document.createElement("script");
        recaptchaScript2.setAttribute(
            "src",
            "./js/slick/slick.js"
        );
        document.head.appendChild(recaptchaScript2);
        let recaptchaScript3 = document.createElement("script");
        recaptchaScript3.setAttribute(
            "src",
            "./js/slick/slick.min.js"
        );
        document.head.appendChild(recaptchaScript3);
        const recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
            "src",
            "./js/script.js"
        );
        document.head.appendChild(recaptchaScript);

        window.scrollTo({ top: 0, behavior: "smooth" });

        this.getResults();
    },
}
</script>

<style scoped>
.paginateBtn{
    display: flex;
}

</style>